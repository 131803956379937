import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47Sample_45Page_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/Sample-Page.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Login_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/auth/Login.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Register_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/auth/Register.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47clients_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/clients/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47dashboard_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/dashboard/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45leave_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/employee-leave/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45shifts_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/employee-shifts/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/employee/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47incidents_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/home/incidents.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/home/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47notes_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/home/notes.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47report_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/home/report.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47shifts_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/home/shifts.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47incident_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/incident/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47old_index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/old_index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47report_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/report/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47_91id_93_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/schedule/[id].vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/schedule/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47shift_45note_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/shift-note/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47shifts_47index_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/shifts/index.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Shadow_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/ui/Shadow.vue?macro=true";
import { default as _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Typography_46vueMeta } from "/home/dally/Dev/scheduring-web/pages/ui/Typography.vue?macro=true";
export default [
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47Sample_45Page_46vueMeta?.name ?? "Sample-Page",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47Sample_45Page_46vueMeta?.path ?? "/Sample-Page",
    file: "/home/dally/Dev/scheduring-web/pages/Sample-Page.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47Sample_45Page_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47Sample_45Page_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47Sample_45Page_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/Sample-Page.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Login_46vueMeta?.name ?? "auth-Login",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Login_46vueMeta?.path ?? "/auth/Login",
    file: "/home/dally/Dev/scheduring-web/pages/auth/Login.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Login_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Login_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Login_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/auth/Login.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Register_46vueMeta?.name ?? "auth-Register",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Register_46vueMeta?.path ?? "/auth/Register",
    file: "/home/dally/Dev/scheduring-web/pages/auth/Register.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Register_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Register_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47auth_47Register_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/auth/Register.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47clients_47index_46vueMeta?.name ?? "clients",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47clients_47index_46vueMeta?.path ?? "/clients",
    file: "/home/dally/Dev/scheduring-web/pages/clients/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47clients_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47clients_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47clients_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47dashboard_47index_46vueMeta?.name ?? "dashboard",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47dashboard_47index_46vueMeta?.path ?? "/dashboard",
    file: "/home/dally/Dev/scheduring-web/pages/dashboard/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47dashboard_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47dashboard_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47dashboard_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45leave_47index_46vueMeta?.name ?? "employee-leave",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45leave_47index_46vueMeta?.path ?? "/employee-leave",
    file: "/home/dally/Dev/scheduring-web/pages/employee-leave/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45leave_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45leave_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45leave_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/employee-leave/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45shifts_47index_46vueMeta?.name ?? "employee-shifts",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45shifts_47index_46vueMeta?.path ?? "/employee-shifts",
    file: "/home/dally/Dev/scheduring-web/pages/employee-shifts/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45shifts_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45shifts_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_45shifts_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/employee-shifts/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_47index_46vueMeta?.name ?? "employee",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_47index_46vueMeta?.path ?? "/employee",
    file: "/home/dally/Dev/scheduring-web/pages/employee/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47employee_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/employee/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47incidents_46vueMeta?.name ?? "home-incidents",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47incidents_46vueMeta?.path ?? "/home/incidents",
    file: "/home/dally/Dev/scheduring-web/pages/home/incidents.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47incidents_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47incidents_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47incidents_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/home/incidents.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47index_46vueMeta?.name ?? "home",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47index_46vueMeta?.path ?? "/home",
    file: "/home/dally/Dev/scheduring-web/pages/home/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47notes_46vueMeta?.name ?? "home-notes",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47notes_46vueMeta?.path ?? "/home/notes",
    file: "/home/dally/Dev/scheduring-web/pages/home/notes.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47notes_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47notes_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47notes_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/home/notes.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47report_46vueMeta?.name ?? "home-report",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47report_46vueMeta?.path ?? "/home/report",
    file: "/home/dally/Dev/scheduring-web/pages/home/report.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47report_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47report_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47report_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/home/report.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47shifts_46vueMeta?.name ?? "home-shifts",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47shifts_46vueMeta?.path ?? "/home/shifts",
    file: "/home/dally/Dev/scheduring-web/pages/home/shifts.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47shifts_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47shifts_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47home_47shifts_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/home/shifts.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47incident_47index_46vueMeta?.name ?? "incident",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47incident_47index_46vueMeta?.path ?? "/incident",
    file: "/home/dally/Dev/scheduring-web/pages/incident/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47incident_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47incident_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47incident_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/incident/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47index_46vueMeta?.name ?? "index",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47index_46vueMeta?.path ?? "/",
    file: "/home/dally/Dev/scheduring-web/pages/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47old_index_46vueMeta?.name ?? "old_index",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47old_index_46vueMeta?.path ?? "/old_index",
    file: "/home/dally/Dev/scheduring-web/pages/old_index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47old_index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47old_index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47old_index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/old_index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47report_47index_46vueMeta?.name ?? "report",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47report_47index_46vueMeta?.path ?? "/report",
    file: "/home/dally/Dev/scheduring-web/pages/report/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47report_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47report_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47report_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/report/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47_91id_93_46vueMeta?.name ?? "schedule-id",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47_91id_93_46vueMeta?.path ?? "/schedule/:id",
    file: "/home/dally/Dev/scheduring-web/pages/schedule/[id].vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47_91id_93_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47_91id_93_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47_91id_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/schedule/[id].vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47index_46vueMeta?.name ?? "schedule",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47index_46vueMeta?.path ?? "/schedule",
    file: "/home/dally/Dev/scheduring-web/pages/schedule/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47schedule_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47shift_45note_47index_46vueMeta?.name ?? "shift-note",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47shift_45note_47index_46vueMeta?.path ?? "/shift-note",
    file: "/home/dally/Dev/scheduring-web/pages/shift-note/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47shift_45note_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47shift_45note_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47shift_45note_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/shift-note/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47shifts_47index_46vueMeta?.name ?? "shifts",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47shifts_47index_46vueMeta?.path ?? "/shifts",
    file: "/home/dally/Dev/scheduring-web/pages/shifts/index.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47shifts_47index_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47shifts_47index_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47shifts_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/shifts/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Shadow_46vueMeta?.name ?? "ui-Shadow",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Shadow_46vueMeta?.path ?? "/ui/Shadow",
    file: "/home/dally/Dev/scheduring-web/pages/ui/Shadow.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Shadow_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Shadow_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Shadow_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/ui/Shadow.vue").then(m => m.default || m)
  },
  {
    name: _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Typography_46vueMeta?.name ?? "ui-Typography",
    path: _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Typography_46vueMeta?.path ?? "/ui/Typography",
    file: "/home/dally/Dev/scheduring-web/pages/ui/Typography.vue",
    children: [],
    meta: _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Typography_46vueMeta,
    alias: _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Typography_46vueMeta?.alias || [],
    redirect: _47home_47dally_47Dev_47scheduring_45web_47pages_47ui_47Typography_46vueMeta?.redirect || undefined,
    component: () => import("/home/dally/Dev/scheduring-web/pages/ui/Typography.vue").then(m => m.default || m)
  }
]