import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/home/dally/Dev/scheduring-web/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/home/dally/Dev/scheduring-web/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/home/dally/Dev/scheduring-web/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64vueuse_motion_dist_runtime_templates_motion_mjs_fmZWau4t0U from "/home/dally/Dev/scheduring-web/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "/home/dally/Dev/scheduring-web/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import plugins_datatables_client_ts_mNP1eTBmJI from "/home/dally/Dev/scheduring-web/plugins/datatables.client.ts";
import plugins_primedialog_ts_qFsiuar8My from "/home/dally/Dev/scheduring-web/plugins/primedialog.ts";
import plugins_primetoast_ts_lsWtd1wTVR from "/home/dally/Dev/scheduring-web/plugins/primetoast.ts";
import plugins_primevue_plugin_ts_z8oRRCPSHr from "/home/dally/Dev/scheduring-web/plugins/primevue-plugin.ts";
import plugins_primevue_js_TdXjRgL1MA from "/home/dally/Dev/scheduring-web/plugins/primevue.js";
import plugins_vuetify_ts_7h9QAQEssH from "/home/dally/Dev/scheduring-web/plugins/vuetify.ts";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64vueuse_motion_dist_runtime_templates_motion_mjs_fmZWau4t0U,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  plugins_datatables_client_ts_mNP1eTBmJI,
  plugins_primedialog_ts_qFsiuar8My,
  plugins_primetoast_ts_lsWtd1wTVR,
  plugins_primevue_plugin_ts_z8oRRCPSHr,
  plugins_primevue_js_TdXjRgL1MA,
  plugins_vuetify_ts_7h9QAQEssH
]